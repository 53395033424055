import { differenceInHours, formatISODuration } from 'date-fns';

interface ResultType {
    booking: CheckoutBooking | undefined;
    passengers: Passenger[];
    offers: Offers;
    optionalOffers: OptionalOffer[];
    products: Products;
    vouchers: Voucher[];
    termsAndConditions: ApiSchema['TermsAndCondition'][];
}
type ApiResponseType = ApiSchema['BookingResponse'];
type ParamsType = object;

/**
 * Loads a booking by its id.
 *
 * Based on the type of booking, the result will re-create all its details
 * (offers, passengers, products, vouchers).
 */
export default async function apiLoadBooking(id: string): Promise<ApiResult<ResultType>> {
    try {
        return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            `/bookings/${id}`,

            // Request Params
            {},
            // Transform result
            (response: ApiResponseType) => ({
                booking: transformBooking(response),
                passengers: transformPassengers(response),
                offers: transformOffers(response),
                products: transformProducts(response),
                optionalOffers: transformOptionalOffers(response),
                vouchers: transformVouchers(response),
                termsAndConditions: transformTermsAndConditions(response),
            } satisfies ResultType),
        );
    }
    catch (error: any) {
        const isFatal = (error.status === 404 || error.status === 400) ? { statusCode: 404, fatal: true } : false;
        handleError(error, 'generic', isFatal);

        return {
            ok: false,
            warnings: [],
            data: {
                booking: undefined,
                passengers: [],
                offers: {} as Offers,
                optionalOffers: [],
                products: {} as Products,
                vouchers: [],
                termsAndConditions: [],
            } satisfies ResultType,
        };
    }
}

/**
 *
 */
function transformBooking(response: ApiResponseType) {
    return {
        id: response.booking?.id ?? '',
        bookingNo: response.booking?.bookingNo ?? '',
        openAmount: response.booking?.openAmount,
        confirmedPrice: response.booking?.confirmedPrice,
        confirmationTimeLimit: zurichDate(response.booking?.confirmationTimeLimit),
        fulfillments: response.booking?.fulfillments ?? [],
        documents: response.booking?.documents ?? [],
    } satisfies CheckoutBooking;
}

/**
 *
 */
function transformPassengers(response: ApiResponseType) {
    return response.booking?.passengers?.map<Passenger>(p => ({
        uuid: '',
        ref: p.externalRef,
        firstName: p.detail?.firstName ?? '',
        lastName: p.detail?.lastName ?? '',
        dateOfBirth: zurichDate(p.dateOfBirth),
        keycard: undefined,
        isKeycardRequired: false,
        isReductionCardRequired: false,
        reductionCards: [],
    })) ?? [];
}

/**
 *
 */
function transformOffers(response: ApiResponseType) {
    return response.booking?.bookedOffers?.reduce((acc, o) => {
        if (o.activities?.length) {
            const activity = o.activities[0];

            const productConfig = configProducts
                .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
                .find(pc => pc.productId === activity?.activityId);

            const passengerRefs = response.booking?.passengers.filter(p => o.activities?.some(a => a.passengerIds.includes(p.id))).map(p => p.externalRef) ?? [];

            const offerParts = o.activities.reduce((acc: Record<string, OfferPart>, activity) => {
                const passengerRefs = response.booking?.passengers.filter(p => activity?.passengerIds.includes(p.id)).map(p => p.externalRef) ?? [];
                const passengerRef = passengerRefs[0] ?? '';
                acc[passengerRef] = {
                    passengerRef,
                    price: activity.price,
                };
                return acc;
            }, {});

            if (productConfig) {
                acc[productConfig.key] = {
                    offerId: o.offerId,
                    productKey: productConfig?.key ?? '',
                    passengerRefs,
                    offerParts,
                    passengerWarnings: {},
                };
            }
        }

        return acc;
    }, {} as Offers) ?? {} as Offers;
}

/**
 *
 */
function transformOptionalOffers(response: ApiResponseType) {
    return response.booking?.bookedOffers?.map<OptionalOffer>((o) => {
        const offer: OptionalOffer = {} as OptionalOffer;

        if (o.ancillaries?.[0]) {
            const part = o.ancillaries?.[0];
            if (part.type === 'INSURANCE') {
                offer.offerId = o.offerId;
                offer.offerRef = part.type;
                offer.price = part.price;
                offer.type = 'insurance';
                offer.name = part.summary ?? '';
                offer.info = undefined;
            }
        }

        return offer;
    }) ?? [];
}

/**
 *
 */
function transformProducts(response: ApiResponseType) {
    return response.booking?.bookedOffers?.reduce((acc, o) => {
        if (o.activities?.length) {
            const activity = o.activities[0];

            const productConfig = configProducts
                .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
                .find(pc => pc.productId === activity?.activityId);

            const passengerRefs = response.booking?.passengers.filter(p => activity?.passengerIds.includes(p.id)).map(p => p.externalRef) ?? [];
            const dateFrom = activity?.validFrom;
            const dateUntil = activity?.validUntil;

            // TODO: Ask backend if they can provide duration the same way as for offers
            const durationInHours = (dateFrom && dateUntil) ? Math.abs(differenceInHours(dateFrom, dateUntil)) : 0;
            const duration = formatISODuration({ hours: durationInHours });

            if (productConfig) {
                acc[productConfig.key] = {
                    key: productConfig?.key,
                    type: productConfig?.type,
                    name: productConfig?.name ?? '',
                    activityId: productConfig?.productId ?? '',
                    dateFrom: zurichDate(dateFrom),
                    dateUntil: zurichDate(dateUntil),
                    duration,
                    passengerRefs,
                    availableFulfillmentOptions: [],
                };
            }
        }

        return acc;
    }, {} as Products) ?? {} as Products;
}

/**
 *
 */
function transformVouchers(response: ApiResponseType) {
    return response.booking?.paymentMethods?.filter(pm => pm.type === 'VOUCHER').map<Voucher>(v => ({
        issuer: v.provider ?? '',
        code: v.voucherInformation?.code ?? '',
        amount: v.amount ?? { amount: 0, currency: 'CHF' },
    })) ?? [];
}

/**
 *
 */
function transformTermsAndConditions(response: ApiResponseType) {
    return response.booking?.termsAndConditions ?? [];
}
